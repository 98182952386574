@charset "utf-8";

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Bold.woff2") format("woff2"),
    url("../fonts/Gotham-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Book";
  src: url("../fonts/Gotham-Book.woff2") format("woff2"),
    url("../fonts/Gotham-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

// Colors

$black: hsl(0, 0%, 4%);
$black-bis: hsl(0, 0%, 7%);
$black-ter: hsl(0, 0%, 14%);

$grey-darker: hsl(0, 0%, 21%);
$grey-dark: hsl(0, 0%, 29%);
$grey: hsl(0, 0%, 48%);
$grey-light: hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 86%);

$white-ter: hsl(0, 0%, 96%);
$white-bis: hsl(0, 0%, 98%);
$white: hsl(0, 0%, 100%);

$orange: hsl(14, 100%, 53%);
$yellow: hsl(48, 100%, 67%);
$green: hsl(141, 71%, 48%);
$turquoise: hsl(171, 100%, 41%);
$cyan: hsl(204, 86%, 53%);
$purple: hsl(271, 100%, 71%);
$red: hsl(348, 100%, 61%);
$button-background-color: rgba(22, 22, 50, 0.02);
$brown: #cd7f32;
$blue: #161632;
$blueLight: #2e2e47;

// Typography

$family-monospace: monospace;
$render-mode: optimizeLegibility;

$size-1: 3rem;
$size-2: 2.5rem;
$size-3: 2rem;
$size-4: 1.5rem;
$size-5: 1.25rem;
$size-6: 1rem;
$size-7: 0.75rem;
$size-8: 0.625rem;
$weight-light: 300;
$weight-normal: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;

// Responsiveness

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 32px;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px;
// 960px container + 4rem
$desktop: 960px + (2 * $gap);
// 1152px container + 4rem
$widescreen: 1152px + (2 * $gap);
$widescreen-enabled: true;
// 1344px container + 4rem
$fullhd: 1344px + (2 * $gap);
$fullhd-enabled: true;

// Miscellaneous

$easing: ease-out;
$radius-small: 4px;
$radius: 4px;
$radius-large: 4px;
$radius-rounded: 290486px;
$speed: 86ms;

// Flags
$variable-columns: true;
$primary: $blue;
$link: $blueLight;

// Update some of Bulma's component variables
$body-background-color: #fafbfc;
$control-border-width: 1px;
//$input-border-color: transparent;
$input-shadow: none;
$table-cell-border-width: 0;
$table-head-cell-border-width: 0 0 1px 0;
$box-shadow: 0 0 7px 0 rgba(22, 22, 50, 0.05);
$box-shadow-hover: 0 0 7px 0 rgba(22, 22, 50, 0.12);

// Update Bulma's global variables
$family-sans-serif: "Gotham Book";
$family-bold: "Gotham";
$grey-dark: #696969;
$grey-light: #161632;
$primary: $blue;
$link: $blueLight;
$widescreen-enabled: false;
$fullhd-enabled: false;
$paddingContainer: 1rem;
$material-icons-font-path: "~material-icons/iconfont/";

// z-index
$layer-modal: 8;
$layer-blocker: 7;
$layer-burger: 6;
$layer-sticky: 5;
$layer-tooltip: 4;
$layer-screen-attached: 3;
$layer-blur-handler: 2;
$layer-content-attached: 1;
$layer-content: 0;

@import "../../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../../node_modules/bulma/sass/base/_all.sass";
@import "../../../node_modules/bulma/sass/form/_all.sass";
@import "../../../node_modules/bulma/sass/layout/_all.sass";
@import "../../../node_modules/bulma/sass/components/_all.sass";
@import "../../../node_modules/bulma/sass/elements/_all.sass";
@import "../../../node_modules/bulma/sass/grid/_all.sass";
@import "../../../node_modules/bulma-o-steps/bulma-steps.sass";
@import "../../../node_modules/bulma-extensions/bulma-steps/src/sass/index.sass";

.main {
  padding: 1rem;
  width: 100%;
}

.react-datepicker__input-container {
  height: 40px;
}

.pagination-list {
  button {
    cursor: pointer;
    font-size: 1em;
    justify-content: center;
    margin: 0.25rem;
    padding: 0 0.5em;
    text-align: center;
  }
}

.control.has-icons-left {
  .icon {
    height: 2em;
  }
}

.select {
  .loader {
    margin: 0.5rem;
  }
}

.navbar-user {
  margin-right: 1em;
  right: 0;
  position: fixed;
  a {
    margin-left: 0.5em;
    font-size: 0.8em;
  }
}